{
  "lineStyle": [
    {
      "key": "../../assets/icon/LineStyle_Solid.png",
      "style": "Solid"
    },
    {
      "key": "../../assets/icon/LineStyle_ShortDash.png",
      "style": "ShortDash"
    },
    {
      "key": "../../assets/icon/LineStyle_ShortRound.png",
      "style": "ShortRound"
    },
    {
      "key": "../../assets/icon/LineStyle_LongRound.png",
      "style": "LongRound"
    },
    {
      "key": "../../assets/icon/LineStyle_LongDash.png",
      "style": "LongDash"
    },
    {
      "key": "../../assets/icon/LineStyle_LongRoundDot.png",
      "style": "LongRoundDot"
    },
    {
      "key": "../../assets/icon/LineStyle_LongDashDot.png",
      "style": "LongDashDot"
    },
    {
      "key": "../../assets/icon/LineStyle_LongDashDotDot.png",
      "style": "LongDashDotDot"
    },
    {
      "key": "../../assets/icon/LineStyle_LongRoundDotDot.png",
      "style": "LongRoundDotDot"
    },
    {
      "key": "../../assets/icon/LineStyle_CustomRoundDot.png",
      "style": "CustomRoundDot"
    }
  ]
}
