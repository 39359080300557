import MarkModel from "../../../app/models/marks/MarkModel";
import AxiosClient from "../client/AxiosClient";
import { ResultType } from "../models/Result";
import { convertRgbToHex } from "./ProductService";
import serviceUrlConstants from "./serviceUrlConstants";

export default class MarkService {
  async addMark(projectId: string, mark: MarkModel): Promise<MarkModel> {
    let updatedmark: MarkModel;
    let url = await serviceUrlConstants.getAddMarkUrl(projectId);

    try {
      const response = await AxiosClient.post(
        url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": localStorage["i18nextLng"],
          },
          timeout: 30000,
        },
        mark
      );

      switch (response.type) {
        case ResultType.SUCCESS: {
          updatedmark = this.mapMarkResponseToModel(response.value);
          return updatedmark;
        }
        case ResultType.FAIL:
          throw response.message;
      }
    } catch (error) {
      throw error;
    }
  }
  async updatemark(
    markModel: MarkModel,
    index: number,
    projectId: string
  ): Promise<MarkModel> {
    if (!projectId) {
      throw new Error("ProjectId not found");
    }
    let updatedMarkModel: MarkModel;
    let url = serviceUrlConstants.getUpdateMarkUrl(projectId, index);
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage["i18nextLng"],
        },
        timeout: 30000,
      },
      markModel
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            updatedMarkModel = this.mapMarkResponseToModel(response.value);
            return updatedMarkModel;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return updatedMarkModel;
  }
  async deleteMark(index: number, projectId: string): Promise<string> {
    let deleteResult: string;
    let url = serviceUrlConstants.getDeleteMarkUrl(index, projectId);
    await AxiosClient.delete(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
      timeout: 30000,
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            deleteResult = response.value;
            return deleteResult;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return deleteResult;
  }
  mapMarkResponseToModel(mark: MarkModel) {
    mark.rgbValue = convertRgbToHex(mark.rgbValue);
    mark.translatedCurrentValue = mark.current.toLocaleString(
      localStorage.getItem("i18nextLng")
    );
    mark.translatedTimeValue = mark.time.toLocaleString(
      localStorage.getItem("i18nextLng")
    );

    return mark;
  }
  async getMarkList(
    projectId: string,
    view: string,
    deviceId?: number
  ): Promise<MarkModel[]> {
    let markList: MarkModel[];
    let url = serviceUrlConstants.getMarkListUrl(projectId, view, deviceId);
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      timeout: 30000,
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            markList = this.mapMarkModel(response.value);
            return markList;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });

    return markList;
  }
  mapMarkModel(markList: MarkModel[]) {
    markList.forEach((mark) => {
      mark.rgbValue = convertRgbToHex(mark.rgbValue);
      mark.translatedCurrentValue = mark.current.toLocaleString(
        localStorage.getItem("i18nextLng")
      );
      mark.translatedTimeValue = mark.time.toLocaleString(
        localStorage.getItem("i18nextLng")
      );
    });

    return markList;
  }
}
