import CatalogEnvironmentVariables from "../../../app/models/catalog/CatalogEnvironmentVariables";
import { CatalogAttributeModel } from "../../../app/models/catalog/CatalogAttributesModel";
import { Constants } from "../../../app/models/constants/Constants";
import { CatalogAttributeValueModel } from "../../models/catalog/CatalogAttributeValueModel";
import { ProductNode } from "../../models/catalog/CatalogProductNodeModel";
import AxiosClient from "../client/AxiosClient";
import ListEntry from "../../models/devices/ListEntry";
import Product from "../../models/devices/product";
import { ResultType } from "../models/Result";
import serviceUrlConstants from "./serviceUrlConstants";

export default class ProductCatalogService {
  async getCatalogTree(): Promise<ProductNode> {
    let productNode: ProductNode = new ProductNode();
    let url = serviceUrlConstants.getMDSUrl();
    // debugger;
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            let productNodeFromBE = response.value.data.rootCategory;
            productNode = productNodeFromBE;
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return productNode;
      })
      .catch((error) => {
        throw error;
      });
    return productNode;
  }
  async getProductAttributes(
    productGroup: string
  ): Promise<CatalogAttributeModel[]> {
    let productAttributeList = new Array<CatalogAttributeModel>();
    let url = serviceUrlConstants.getMDSProductAttribute(productGroup);
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            // let productNodeFromBE = response.value;
            productAttributeList = this.mapCatalogProductAttributes(
              response.value
            );
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return productAttributeList;
      })
      .catch((error) => {
        throw error;
      });
    return productAttributeList;
  }
  async postProductAttributes(
    productGroup: string,
    requestBody
  ): Promise<CatalogAttributeModel[]> {
    let productAttributeList = new Array<CatalogAttributeModel>();
    let url = serviceUrlConstants.getMDSProductAttribute(productGroup);
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage["i18nextLng"],
        },
      },
      requestBody
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            // let productNodeFromBE = response.value;
            productAttributeList = this.mapCatalogProductAttributes(
              response.value
            );
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return productAttributeList;
      })
      .catch((error) => {
        throw error;
      });
    return productAttributeList;
  }

  async resetProductAttributes(
    productGroup: string
  ): Promise<CatalogAttributeModel[]> {
    let productAttributeList = new Array<CatalogAttributeModel>();
    let url = serviceUrlConstants.resetProductAttribute(productGroup);
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            // let productNodeFromBE = response.value;
            productAttributeList = this.mapCatalogProductAttributes(
              response.value
            );
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return productAttributeList;
      })
      .catch((error) => {
        throw error;
      });
    return productAttributeList;
  }
  async getProductGroupForSearchedPattern(
    searchedPattern: string
  ): Promise<string> {
    let productGroup: string;
    let url =
      serviceUrlConstants.getProductGroupForSearchedPatternUrl(searchedPattern);
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            productGroup = response.value;
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return productGroup;
      })
      .catch((error) => {
        throw error;
      });
    return productGroup;
  }
  mapCatalogProductAttributes(response) {
    let attributesList = new Array<CatalogAttributeModel>();
    if (response) {
      response.forEach((el) => {
        if (el.values) {
          let valueList = el.values;
          el.values = [];
          valueList.forEach((val) => {
            if (val) {
              let valueObj = new CatalogAttributeValueModel();
              valueObj.value = val.value;
              valueObj.displayValue = val.displayValue;
              valueObj.translatedValue = val.toLocaleString(
                localStorage.getItem("i18nextLng")
              );
              valueObj.type = Constants.VALID_VALUE;
              el.values.push(valueObj);
            }
          });
        }
        if (el.requested_value && el.invalidValues) {
          if (el.requested_value) {
            let valueObj = new CatalogAttributeValueModel();
            el.values = [];
            valueObj.value = el.requested_value.value;
            valueObj.displayValue = el.requested_value.displayValue;

            valueObj.translatedValue = el.requested_value.toLocaleString(
              localStorage.getItem("i18nextLng")
            );
            valueObj.type = Constants.REQUESTED_VALUE;
            el.values.push(valueObj);
          }

          el.invalidValues.forEach((val) => {
            if (val) {
              let valueObj = new CatalogAttributeValueModel();
              valueObj.value = val.value;
              valueObj.displayValue = val.displayValue;
              valueObj.translatedValue = val.toLocaleString(
                localStorage.getItem("i18nextLng")
              );
              valueObj.type = Constants.INVALID_VALUE;
              el.values.push(valueObj);
            }
          });
        } else if (el.invalidValues && el.values) {
          el.invalidValues.forEach((val) => {
            if (val) {
              let valueObj = new CatalogAttributeValueModel();
              valueObj.value = val.value;
              valueObj.displayValue = val.displayValue;
              valueObj.translatedValue = val.toLocaleString(
                localStorage.getItem("i18nextLng")
              );
              valueObj.type = Constants.INVALID_VALUE;
              el.values.push(valueObj);
            }
          });
        } else if (el.requested_value) {
          el.translatedValue = el.requested_value.toLocaleString(
            localStorage.getItem("i18nextLng")
          );
        }
        if (!el.requested_value) {
          el.requested_value = new CatalogAttributeValueModel();
          el.requested_value.value = "";
          el.requested_value.displayValue = "";
        }

        attributesList.push(el);
      });
    }
    return attributesList;
  }

  async getMlfb(productGroup: string): Promise<CatalogEnvironmentVariables> {
    let mlfb: CatalogEnvironmentVariables;
    let url = serviceUrlConstants.getMlfbUrl(productGroup);
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            mlfb = response.value;
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return mlfb;
      })
      .catch((error) => {
        throw error;
      });
    return mlfb;
  }

  async addProduct(productGroup: string, mlfb: string): Promise<String> {
    let url = serviceUrlConstants.getAddProductUrl(productGroup, mlfb);
    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage["i18nextLng"],
      },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            mlfb = response.value;
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return mlfb;
      })
      .catch((error) => {
        throw error;
      });
    return mlfb;
  }
  async getProductAttributesForOrderNumber(
    folderId: string,
    projectId: string,
    deviceIndex: number,
    isDraft: boolean
  ): Promise<CatalogAttributeModel[]> {
    let productAttributeList = new Array<CatalogAttributeModel>();
    let url = serviceUrlConstants.getProductAttriburesforLoadingAttributes(
      projectId,
      deviceIndex
    );
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage["i18nextLng"],
        },
      },
      {}
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            // let productNodeFromBE = response.value;
            productAttributeList = this.mapCatalogProductAttributes(
              response.value
            );
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return productAttributeList;
      })
      .catch((error) => {
        throw error;
      });
    return productAttributeList;
  }
  async updateOrderNumber(
    draft: boolean,
    orderNumber: string,
    updatedOrderNumber: string,
    folderId: string,
    projectId: string,
    colorCode: string,
    lineWidth: number,
    lineStyle: string,
    index: number,
    voltage: number,
    ambientTemparature: number
  ): Promise<ListEntry> {
    let listEntry = new ListEntry();
    let url = serviceUrlConstants.updateOrderNumber(
      orderNumber,
      updatedOrderNumber,
      index,
      voltage,
      ambientTemparature,
      projectId
    );
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage["i18nextLng"],
        },
      },
      {
        hexColorCode: colorCode,
        lineStyle: lineStyle,
        lineWidth: lineWidth,
      }
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            listEntry = this.mapLineStyle(response.value);
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return listEntry;
      })
      .catch((error) => {
        throw error;
      });
    return listEntry;
  }

  mapLineStyle(response) {
    let listEntry = new ListEntry();
    listEntry = response;
    listEntry.mProduct = this.mapProductAttributesToModel(listEntry.mProduct);
    listEntry.color = this.convertRgbToHex(listEntry.curveStyle["rgb"]);
    let style, mCap;
    if (listEntry.curveStyle["mLineAttributesWrapper"] != null) {
      listEntry.lineWidth = parseInt(
        listEntry.curveStyle["mLineAttributesWrapper"].mLineWidth
      );
      style = listEntry.curveStyle["mLineAttributesWrapper"].mStyle;
      mCap = listEntry.curveStyle["mLineAttributesWrapper"].mCap;
      listEntry.lineStyle = this.mapCodeToLineStyle(style, mCap);
    } else {
      listEntry.lineWidth = parseInt(
        listEntry.curveStyle["lineAttributes"].width
      );
      style = listEntry.curveStyle["lineAttributes"].style;
      listEntry.lineStyle = "Solid";
    }
    return listEntry;
  }
  mapCodeToLineStyle(mStyle: number, mCap: Number) {
    let lineStyle;
    switch (mStyle) {
      case 1:
        lineStyle = "Solid";
        break;
      case 2:
        switch (mCap) {
          case 1:
            lineStyle = "LongDash";
            break;
          case 2:
            lineStyle = "LongRound";
        }
        break;
      case 3:
        switch (mCap) {
          case 1:
            lineStyle = "ShortDash";
            break;
          case 2:
            lineStyle = "ShortRound";
        }
        break;
      case 4:
        switch (mCap) {
          case 1:
            lineStyle = "LongDashDot";
            break;
          case 2:
            lineStyle = "LongRoundDot";
        }
        break;
      case 5:
        switch (mCap) {
          case 1:
            lineStyle = "LongDashDotDot";
            break;
          case 2:
            lineStyle = "LongRoundDotDot";
        }
        break;
      case 6:
        lineStyle = "CustomRoundDot";
        break;
      default:
        lineStyle = "Solid";
    }
    return lineStyle;
  }
  mapProductAttributesToModel(response: any): Product {
    let productAttribute = new Product();
    productAttribute.attachments = response.m_Attachments;
    productAttribute.attributes = response.m_Attributes;
    productAttribute.mlfb = response.m_strMlfb;
    productAttribute.productgroup = response.m_strProductgroup;
    productAttribute.productgroupID = response.m_strProductgroupID;
    productAttribute.textLanguage = response.m_strTextLanguage;
    productAttribute.quantity = response.m_iQuantity;
    return productAttribute;
  }
  convertRgbToHex(rgb: any): string {
    let hexColor = this.rgbToHex(rgb["red"], rgb["green"], rgb["blue"]);

    return hexColor;
  }
  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
}
