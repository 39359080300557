{
  "colorList": [
    { "key": "flame", "value": "#e45a33" },
    { "key": "orange", "value": "#fa761e" },
    { "key": "infrared", "value": "#ef486e" },
    { "key": "male", "value": "#4488ff" },
    { "key": "female", "value": "#ff44aa" },
    { "key": "paleyellow", "value": "#ffd165" },
    { "key": "gargoylegas", "value": "#fde84e" },
    { "key": "androidgreen", "value": "#9ac53e" },
    { "key": "carribeangreen", "value": "#05d59e" },
    { "key": "bluejeans", "value": "#5bbfea" },
    { "key": "cyancornflower", "value": "#1089b1" },
    { "key": "warmblack", "value": "#06394a" }
  ]
}
