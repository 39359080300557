export default class ProtectiveDevice {
  lowerCurveSeriesTrippingChar: Array<any>;
  upperCurveSeriesTrippingChar: Array<any>;
  letThroughEnergyCurve: Array<any>;
  cutOffCurrentCurve: Array<any>;
  color: string;
  lineStyle: string;
  lineWidth: number;
  currentTimesettings: Array<any>;
  device: any;
  mvDeviceData: any;
  mvCurrentChangerData: any;
}
