import { environment } from "../../../../src/environments/environment";

class ServiceUrlConstants {
  serverAPIURL = environment.apiURL;

  getProductGroupsUrl(page: number, pageSize: number): string {
    return this.serverAPIURL
      .concat("/product/products?page=")
      .concat(page.toString())
      .concat("&pageSize=")
      .concat(pageSize.toString());
  }

  getLogoutUrl(): string {
    return this.serverAPIURL.concat("/logout");
  }

  getDownloadProjectUrl(projectId: string): string {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/file");
  }
  getOrderNumbersUrl(productGroup: string): string {
    return this.serverAPIURL
      .concat("/product/orderNumbers/")
      .concat(productGroup);
  }
  getProductAttributesUrl(orderNumber: string, nominalVoltage: string): string {
    return this.serverAPIURL
      .concat("/product/")
      .concat(encodeURIComponent(orderNumber))
      .concat("/")
      .concat(nominalVoltage);
  }
  getSearchProjectsUrl(
    queryParams: string,
    parentProjectId: string,
    isSearchEnabled: boolean
  ): string {
    return this.serverAPIURL
      .concat("/project/search-projects/")
      .concat(parentProjectId)
      .concat("?queryParams=")
      .concat(queryParams)
      .concat("&isSearchEnabled=")
      .concat(isSearchEnabled + "");
  }
  getProjectUrl(
    projectId: string,
    orderNumber: string = "",
    voltage: number,
    ambientTemparature: number,
    itemType: string,
    isFromCatalog: boolean
  ): string {
    let url = this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices?orderNo=")
      .concat(encodeURIComponent(orderNumber))
      .concat("&ratedVoltage=")
      .concat(voltage ? voltage.toString() : "")
      .concat("&ambientTemperature=")
      .concat(ambientTemparature ? ambientTemparature.toString() : "")
      .concat("&itemType=")
      .concat(itemType ? itemType : "device")
      .concat("&fromCatalog=")
      .concat(isFromCatalog.toString());
    return url;
  }

  synchronizeCurveStyleUrl(projectId: string, index: number): string {
    let url = this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/")
      .concat(index.toString())
      .concat("/style");

    return url;
  }
  synchronizeDeviceUrl(projectId: string, index: number): string {
    let url = this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/")
      .concat(index.toString())
      .concat("/params");
    return url;
  }

  getDeleteDeviceDataUrl(
    // orderNumber: string,
    index: number,
    projectId: string
  ) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/listItem/")
      .concat(index.toString());
  }
  getDeleteMarkUrl(
    // orderNumber: string,
    index: number,
    projectId: string
  ) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/mark/")
      .concat(index.toString());
  }

  getUpdateDeviceLabelUrl(projectId: string, index: number) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/")
      .concat(index.toString())
      .concat("/label");
  }

  getUpdateMarkUrl(projectId: string, index: number) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/updatemark/")
      .concat(index.toString());
  }
  getMDSUrl(): string {
    return this.serverAPIURL.concat("/catalog/mds/all-catalogue");
  }
  getMDSProductAttribute(productGroup: string) {
    return this.serverAPIURL
      .concat("/catalog/mds/getProductAttributes/")
      .concat(productGroup);
  }
  getNominalVoltage() {
    return this.serverAPIURL.concat("/product/getNominalVoltage");
  }
  getNominalVoltageForMlfbUrl(mlfb: string) {
    return this.serverAPIURL
      .concat("/product/")
      .concat(mlfb)
      .concat("/ratedVoltage");
  }
  getMlfbUrl(productGroup: string): string {
    return this.serverAPIURL
      .concat("/catalog/mds/getMlfb/")
      .concat(productGroup);
  }
  getAddProductUrl(productGroup: string, mlfb: string): string {
    return this.serverAPIURL
      .concat("/catalog/mds/addProduct/")
      .concat(productGroup)
      .concat("/")
      .concat(encodeURIComponent(mlfb));
  }

  getAllProjects(): string {
    return this.serverAPIURL.concat("/projects");
  }

  getDriveProjectInfoUrl(projectId: string): string {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/meta");
  }

  deleteProjectUrl(projectId: string): string {
    return this.serverAPIURL.concat("/projects/").concat(projectId);
  }

  getUpdateProjectUrl(projectId: string): string {
    return this.serverAPIURL.concat("/projects/").concat(projectId);
  }

  createProjectUrl(): string {
    return this.serverAPIURL.concat("/projects");
  }

  resetProductAttribute(productGroup: string) {
    return this.serverAPIURL
      .concat("/catalog/mds/resetAttributes/")
      .concat(productGroup);
  }
  getProductGroupForSearchedPatternUrl(searchedPattern: string) {
    return this.serverAPIURL
      .concat("/catalog/mds/init?pattern=")
      .concat(encodeURIComponent(searchedPattern));
  }
  getDriveProjectUrl(queryParams?: string, isSearchEnabled?: boolean): string {
    queryParams ? (isSearchEnabled = true) : (isSearchEnabled = false);
    return (
      this.serverAPIURL
        .concat("/projects?queryParams=")
        .concat(encodeURIComponent(queryParams))
        .concat("&isSearchEnabled=") + isSearchEnabled
    );
  }

  fileImportUrl(projectId: string) {
    return this.serverAPIURL.concat("/projects/").concat(projectId);
  }

  serverFileImportUrl(projectid: string) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectid)
      .concat("/save");
  }

  getSaveConfiguration(): string {
    return this.serverAPIURL.concat("/project/devices");
  }

  getImportedDevices(projectId: string): string {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices");
  }
  getDownloadUrl() {
    return this.serverAPIURL.concat("/project/download");
  }
  // getProductAttributeForOrderNumber(
  //   productGroup: string,
  //   orderNumber: string,
  //   voltage: number,
  //   ambientTemparature: number) {
  //   return this.serverAPIURL
  //     .concat("/catalog/mds/loadAttributes/")
  //     .concat(productGroup)
  //     .concat("/")
  //     .concat(encodeURIComponent(orderNumber))
  //     .concat("?&ratedVoltage=")
  //     .concat(voltage ? voltage.toString() : "")
  //     .concat("&ambientTemperature=")
  //     .concat(ambientTemparature ? ambientTemparature.toString() : "");
  // }

  getProductAttriburesforLoadingAttributes(
    projectId: string,
    deviceIndex: number
  ) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/")
      .concat(deviceIndex.toString())
      .concat("/loadIntoCatalog");
  }
  updateOrderNumber(
    orderNumber: string,
    updatedOrderNumber: string,
    index: number,
    voltage: number,
    ambientTemparature: number,
    projectId: string
  ) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/")
      .concat(index.toString())
      .concat("/replace?orderNo=")
      .concat(encodeURIComponent(orderNumber))
      .concat("&updatedOrderNo=")
      .concat(encodeURIComponent(updatedOrderNumber))
      .concat("&ratedVoltage=")
      .concat(voltage ? voltage.toString() : "")
      .concat("&ambientTemperature=")
      .concat(ambientTemparature ? ambientTemparature.toString() : "");
  }

  getTranslationServiceUrl(): string {
    return this.serverAPIURL.concat("/translation");
  }

  getExportProjectUrl(projectId: string): string {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/pdf");
  }

  getRefreshSessionUrl(projectId: string) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/refreshSessionData");
  }
  getKeepAliveUrl() {
    return this.serverAPIURL.concat("/session/heartbeat");
  }

  getmoveListItemUrl(
    projectId: string,
    currentIdx: number,
    updatedIdx: number,
    view: string
  ) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/updatePostion?currentIndex=")
      .concat(currentIdx.toString())
      .concat("&updatedIndex=")
      .concat(updatedIdx.toString())
      .concat("&view=")
      .concat(view);
  }

  getmoveListItemDownUrl(index: number, projectId: string) {
    return this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/devices/")
      .concat(index.toString())
      .concat("/down");
  }

  getAddMarkUrl(projectId: string): string {
    let url = this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/addmark");
    return url;
  }
  getMarkListUrl(projectId: string, view: string, deviceId?: number): string {
    let url = this.serverAPIURL
      .concat("/projects/")
      .concat(projectId)
      .concat("/markList?deviceIndex=")
      .concat(deviceId !== undefined ? deviceId.toString() : "-1")
      .concat("&view=")
      .concat(view);
    return url;
  }

  getCountryUrl() {
    let url = this.serverAPIURL.concat("/project/country");
    return url;
  }
  //Endpoints for login/registration
  getVerifyUrl(mailId) {
    return environment.serviceProxyUrl.concat("/authz/login/verify");
  }
  getRegistrationIntUserUrl() {
    return environment.serviceProxyUrl.concat("/authz/users/app/registration");
  }
  getRegistrationExtUserUrl() {
    return environment.serviceProxyUrl.concat("/authz/users/registration");
  }
  getRegistrationVerifyUrl() {
    return environment.serviceProxyUrl.concat(
      "/authz/users/registration/verify"
    );
  }

  //Endpoints for the /curves route

  getMrpdUrl(country: string, mrpd: string) {
    return (
      this.serverAPIURL
        .concat("/v2/pds/")
        .concat(country)
        .concat("/")
        .concat("CURVES")
        // FIXME: make it configurable
        .concat("/")
        .concat(mrpd)
    );
  }
}

export default new ServiceUrlConstants();
