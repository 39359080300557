import axios from "axios";
import { Fail, Result, Success } from "../models/Result";

class AxiosClient {
  GetConfigurationHeader(
    token: string,
    contentType: string = "application/vnd.api+json"
  ) {
    let config = {
      "Content-Type": contentType,
      Accept: contentType,
      "Accept-Language": localStorage["i18nextLng"],
    };
    return config;
  }

  GetHeader(contentType: string = "application/vnd.api+json") {
    let config = {
      "Content-Type": contentType,
      "Accept-Language": localStorage["i18nextLng"],
      // Accept: contentType,
    };
    return config;
  }

  async post(
    url: string,
    headers: any,
    requestBody: any
  ): Promise<Result<any>> {
    let result: any;
    if (document.cookie) {
      // headers["Set-Cookie"] = document.cookie;
      headers["withCredentials"] = true;
      headers["Accept-Language"] = localStorage["i18nextLng"];
    }
    try {
      await axios
        .post(url, requestBody, headers)
        .then((response) => {
          result = Success(response.data);
          return result;
        })
        .catch(function (error) {
          result = Fail(error);
          return result;
        });
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  async get(
    url: string,
    headers: any,
    reponseType: string = ""
  ): Promise<Result<any>> {
    let result: any;
    let config = {
      withCredentials: true,
      headers: {
        "Accept-Language": localStorage["i18nextLng"],
      },
    };
    if (reponseType === "blob") {
      config["responseType"] = "blob";
    }
    if (reponseType === "arraybuffer") {
      config["responseType"] = "arraybuffer";
    }
    if (document.cookie) {
      // config["Set-Cookie"] = document.cookie;
    }
    try {
      await axios
        .get(url, config)
        .then((response) => {
          result = Success(response.data);
        })
        .catch(function (error) {
          result = Fail(error);
        });
    } catch (e) {
      console.log(e);
    }
    return result;
  }
  async getWithRequestHeaders(
    url: string,
    headers: any,
    reponseType: string = ""
  ): Promise<Result<any>> {
    let result: any;
    let config = {
      withCredentials: true,
      headers: {
        "Accept-Language": localStorage["i18nextLng"],
      },
    };
    if (reponseType === "blob") {
      config["responseType"] = "blob";
    }
    if (reponseType === "arraybuffer") {
      config["responseType"] = "arraybuffer";
    }
    try {
      await axios
        .get(url, config)
        .then((response) => {
          result = Success(response);
        })
        .catch(function (error) {
          result = Fail(error);
        });
    } catch (e) {
      console.log(e);
    }
    return result;
  }
  async put(url: string, headers: any, requestBody: any): Promise<Result<any>> {
    let result: any;
    // await serviceFactory.SessionTimerService.resetTimer("reset");
    if (document.cookie) {
      // headers["Set-Cookie"] = document.cookie;
      headers["withCredentials"] = true;
      headers["Accept-Language"] = localStorage["i18nextLng"];
    }
    await axios
      .put(url, requestBody, headers)
      .then((response) => {
        console.log(response, "response");
        result = Success(response.data);
      })
      .catch(function (error) {
        console.log("Error in processing request");
        // // alert(error);
        result = Fail(error);
      });
    return result;
  }
  async delete(url: string, headers: any): Promise<Result<any>> {
    let result: any;
    // await serviceFactory.SessionTimerService.resetTimer("reset");
    if (document.cookie) {
      // headers["Set-Cookie"] = document.cookie;
      headers["Accept-Language"] = localStorage["i18nextLng"];
      headers["withCredentials"] = true;
    }
    try {
      await axios
        .delete(url, headers)
        .then((response) => {
          result = Success(response.data);
          return result;
        })
        .catch(function (error) {
          console.log("Error in processing request");
          // // alert(error);
          result = Fail(error);
          return result;
        });
    } catch (e) {
      console.log(e);
    }
    return result;
  }
  async patch(
    url: string,
    requestHeaders: any,
    requestBody: any
  ): Promise<Result<any>> {
    let result: any;
    let error: any;
    try {
      // await serviceFactory.SessionTimerService.resetTimer("reset");
      await axios
        .patch(url, requestBody, requestHeaders)
        .then((response) => {
          // result = response;
          console.log("response: " + JSON.stringify(result));
          result = Success(response.data);
          return result;
        })
        .catch(function (error) {
          console.log("Error in processing request");
          result = Fail(error);
          return result;
        });
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  async postWithOutSession(
    url: string,
    requestHeaders: any,
    requestBody: any
  ): Promise<Result<any>> {
    let result: any;
    await axios
      .post(url, requestBody, requestHeaders)
      .then((response) => {
        result = Success(response.data);
        return result;
      })
      .catch(function (error) {
        result = Fail(error);
        return result;
      });
    return result;
  }
  async getWithOutSession(url: string, headers: any): Promise<Result<any>> {
    let result: any;
    try {
      await axios
        .get(url, headers)
        .then((response) => {
          result = Success(response.data);
        })
        .catch(function (error) {
          console.log("Error in processing request:" + error);
          result = Fail(error);
        });
    } catch (e) {
      console.log(e);
    }
    return result;
  }
  private async postRequestWithJson(
    url: string,
    headers: any,
    method,
    requestBody: any
  ): Promise<{ response: any; status: "fail" | "success" }> {
    let currentResponse: any = "";
    let result: any;

    const request: RequestInit = {
      method: method,
      headers: headers,
      mode: "same-origin",
    };
    request["body"] = JSON.stringify(requestBody);

    try {
      currentResponse = await fetch(url, request);
      if (currentResponse.ok) {
        result = { response: currentResponse, status: "success" };
      } else {
        result = { response: currentResponse.status, status: "fail" };
      }
    } catch (e: any) {
      result = { response: Fail(e.message), status: "fail" };
    }
    return result;
  }

  async postWithJsonResult(
    url: string,
    data: any,
    headers: any
  ): Promise<Result<any>> {
    const responseHeaders: any = {};

    let response: any = "";
    const postResultResponse = await this.postRequestWithJson(
      url,
      headers,
      "POST",
      data
    );
    response = postResultResponse.response;
    if (postResultResponse.status != "success") {
      return Fail(postResultResponse.status, postResultResponse.response);
    }
    let json;
    try {
      json = await response.json();
      try {
        for (let pair of response.headers.entries()) {
          if (pair[0] === "x-simaris-session") {
          }
          responseHeaders[pair[0]] = pair[1];
        }
      } catch (e) {
        return Fail(response.statusText, response.status);
      }
    } catch (e: any) {
      return Fail(e.message);
    }

    json["statusCode"] = response.status;

    if (response.ok && json) {
      return Success(json);
    } else if (json && json.error) {
      return Fail(json.error, response.status);
    } else {
      // FIXME: This shouldn't be possible.
      return Fail("unknown");
    }
  }
}

export default new AxiosClient();
