{
  "lineWidth": [
    {
      "key": "1",
      "value": "1px solid black"
    },
    {
      "key": "2",
      "value": "2px solid black"
    },
    {
      "key": "3",
      "value": "3px solid black"
    },
    {
      "key": "4",
      "value": "4px solid black"
    },
    {
      "key": "5",
      "value": "5px solid black"
    },
    {
      "key": "6",
      "value": "6px solid black"
    },
    {
      "key": "7",
      "value": "7px solid black"
    },
    {
      "key": "8",
      "value": "8px solid black"
    },
    {
      "key": "9",
      "value": "9px solid black"
    },
    {
      "key": "10",
      "value": "10px solid black"
    }
  ]
}
